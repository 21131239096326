<template>
    <div id="dc-products">
        <div id="dc-header">
            <h3>Products</h3>
            <b-button class="dc-button-primary" @click="onClickGoToNewProduct">Add new</b-button>
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="productsList.length == 0" class="dc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <v-client-table v-else :data="productsList" :columns="columns" :options="options">
            <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                <b-icon-pencil-square class="dc-icon-edit" @click="onClickEditProduct(row.id)"></b-icon-pencil-square>
                <!-- <b-icon-trash-fill class="dc-icon-delete" @click="onClickDeleteProduct(row.id)"></b-icon-trash-fill> -->
            </div>
        </v-client-table>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "Products",
    data() {
        return {
            columns: ["id", "name", "itemType.name", "sku", "price", "quickbooksId", "actions"],
            productsList: [],
            options: {
                perPage: 200,
                templates: {},
                headings: {
                    sku: "SKU",
                    "itemType.name": "Type",
                    quickbooksId: "QB Id",
                },
                orderBy: {
                    column: "id",
                    ascending: true,
                },
            },
        };
    },
    methods: {
        onClickDeleteProduct(id) {
            console.log("Delete Product", id);
        },
        onClickEditProduct(id) {
            this.$router.push({ path: "/edit-product/" + id });
        },
        onClickGoToNewProduct() {
            this.$router.push({ path: "/new-product" });
        },
        async getProducts() {
            try {
                let products = await APICalls.getProducts();
                this.productsList = products;
            } catch (errResponse) {
                console.log(errResponse);
                this.$router.push({ path: "/login" });
            }
        },
    },
    mounted() {
        this.getProducts();
    },
};
</script>

<style lang="scss">
#dc-products {
    #dc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .dc-actions-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .dc-icon-edit {
            width: 22px;
            height: 22px;
            color: black;
            cursor: pointer;
        }

        .dc-icon-delete {
            width: 22px;
            height: 22px;
            margin-left: 30px;
            color: #800000;
            cursor: pointer;
        }
    }

    .dc-button-primary {
        width: 180px;
    }
}
</style>
